import React from "react";
import Header from "../components/Header";
import { Link } from "react-router-dom";
import Loading from "../components/Loading";
import Footer from "../components/Footer";

export default class Dashboard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            token: localStorage.getItem("token"),
            valid: null,
            working: true,
            api_issue: false,
            data: {},
            forms: {},
            class_invites: {},
        }
    }

    validateToken = async (token) => {
        try {
            const request = await fetch(`https://api.educationapp.luke.mx/data?token=${token}`);
            const json = await request.json();

            if (json.success) {
                this.setState({
                    valid: true,
                    data: json.data
                });
            } else {
                this.setState({
                    valid: false
                });
            }

            return json;
        } catch {
            this.setState({
               api_issue: true
            });
        }
    }

    getFormData = async (token, form) => {
        const request = await fetch(`https://api.educationapp.luke.mx/getForm?token=${token}&id=${form}`);
        const json = await request.json();

        if (!json.success) {
            this.setState({
                forms: {
                    [form]: {
                        success: false,
                        reason: json.message
                    }
                }
            })
        } else {
            this.setState({
                forms: {
                    [form]: {
                        success: true,
                        data: json.data
                    }
                }
            })
        }

        return json;
    }

    async componentWillMount() {
        await this.validateToken(this.state.token);

        if (this.state.data?.forms && Array.isArray(this.state.data.forms)) {
            for (const form of this.state.data.forms) {
                const fReq = await fetch(`https://api.educationapp.luke.mx/getForm?token=${this.state.token}&id=${form}`);
                const fJSON = await fReq.json();

                let someProperty = {...this.state.forms};
                someProperty[form] = fJSON;
                this.setState({forms: someProperty})
            }
        }

        this.setState({ working: false });

    }

    render() {

        if (this.state.api_issue === true) {
            return (
                <div>
                    <Header active="dashboard" />
                    <div className="navPusher">
                        <center><h1>You were not able to access the API, it may be down. Try again later.</h1></center>
                    </div>
                </div>
            )
        }

        if (this.state.working) {
            return (<Loading />)
        }

        if (!this.state.token) {
            return (
                <div>
                    <Header active="dashboard" />
                    <div className="navPusher">
                        <center><h1>You need to be logged in to access this page.<br/><Link to="/login">Click here to login to an account if not done already.</Link></h1></center>
                    </div>
                </div>
            )
        } else if (!this.state.data.account?.type)
            return (
                <div>
                    <Header active="dashboard" />
                    <div className="navPusher">
                        <center><h1>You are not logged in, your session may have expired.<br/><Link to="/login">Click here to login to an account if not done already.</Link></h1></center>
                    </div>
                </div>
            )

        function copy (link) {
            const input = document.createElement('input');
            input.value = `https://eduquiz.luke.mx${link}`;

            document.body.appendChild(input);
            input.select();

            document.execCommand('copy');
            document.body.removeChild(input);
        }

        return (
            <div>
                {this.state?.data?.account?.username ? (
                    <Header active="dashboard" logged="true" username={this.state.data.account.username}/>
                ) : (
                    <Header active="dashboard" logged="false"/>
                )}
                <div className="navPusher">
                    <div>
                        <div className="container">
                                <h1>Welcome back to EduQuiz, {this.state.data.account.username}</h1>
                                <h2>Account:</h2>
                                <strong>Type: </strong>
                                <span>{this.state.data.account.type === "teacher" ? "Teacher" : "Student"}</span>
                                <br/>
                                <strong>Username: </strong>
                                <span>{this.state.data.account.username}</span>
                                <br/>
                                <strong>Email: </strong>
                                <span>{this.state.data.account.email}</span><br/>
                                <Link to="/create-form"><button>Create New Form</button></Link> <button disabled>Modify Account</button>
                        </div>
                <div className="text container item-group">
                    <div className="setting item-group">
                        <div>
                        </div>
                        <h2>My Personal Forms</h2>
                        <table class="ms-table">
                            <thead>
                            <tr>
                                <th>Title</th>
                                <th>Questions</th>
                                <th>Submissions</th>
                                <th>Additional Options</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.data.forms.map(form => {
                                const f = this.state.forms[form];
                                if (f?.success) {
                                    return (
                                        <tr>
                                            <td>{f.data?.title ?? "N/A"}</td>
                                            <td>{f.data?.questions.length ?? "N/A"}</td>
                                            <td>
                                                {f.data?.totalSubmissions ?? "N/A"}
                                                <Link to={`/submissions/${form}`}><button style={{
                                                    'margin-left': '10px'
                                                }}>View
                                                </button></Link>
                                            </td>
                                            <td>
                                                <button onClick={() => copy(`/submit-form/${form}`)} style={{
                                                    'margin-right': '10px'
                                                }}>Copy Submit Link
                                                </button>
                                                <Link to={`/form/${form}`}>
                                                    <button style={{
                                                        'margin-right': '10px'
                                                    }}>View Form
                                                    </button>
                                                </Link>
                                            </td>
                                        </tr>
                                    )
                                }
                            })}
                            </tbody>
                        </table>
                        </div>
                        </div>
                    </div><Footer />
                </div>
            </div>
        )
    }

};