import React from 'react';
import Header from "../components/Header";
import {Link} from "react-router-dom";
import Footer from "../components/Footer";

export default class Home extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            valid: null,
            working: false,
            auth_working: true,
            api_issue: false,
            token: localStorage.getItem("token"),
            data: {}
        };
    }

    validateToken = async (token) => {
        try {
            const request = await fetch(`https://api.educationapp.luke.mx/data?token=${token}`);
            const json = await request.json();

            if (json.success) {
                this.setState({
                    valid: true,
                    data: json.data
                });
            } else {
                this.setState({
                    valid: false
                });
            }

            return json;
        } catch {
            this.setState({
                api_issue: true
            });
        }
    }

    async componentWillMount() {
        await this.validateToken(this.state.token);

        this.setState({ auth_working: false });
    }

    render() {
        return (
        <div>
            {this.state?.data?.account?.username ? (
                <Header active="home" logged="true" username={this.state.data.account.username}/>
            ) : (
                <Header active="home" logged="false"/>
            )}
            <div className="navPusher">
                <div>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="welcome col-lg-6 col-sm-14">
                                <h1><span className="color">Accelerate</span> your learning to a new degree using the all-new EduQuiz.</h1>
                                <p>EduQuiz is the brand new quiz creation web application that will accelerate your student's learning. It is completely free, and is available for anyone to use, wherever they may be!<br/><br/>
                                    {this.state?.data?.account?.username ? (
                                        <div>
                                            <Link to="/dashboard" className="ms-btn">
                                                <i className="fas fa-tachometer-alt"/> Dashboard
                                            </Link> <Link to="/create-form" className="ms-btn">
                                            <i className="fas fa-book"/> Create a Form
                                        </Link> <Link to="/logout" className="ms-btn">
                                            <i className="fas fa-sign-out-alt"/> Log Out
                                        </Link>
                                        </div>
                                    ) : (
                                        <div>
                                            <Link to="/login" className="ms-btn">
                                                <i className="fas fa-sign-in-alt"/> Login to your Account
                                            </Link> <Link to="/register" className="ms-btn">
                                            <i className="fas fa-user-plus"/> Create an Account
                                        </Link>
                                        </div>
                                        )}</p>
                            </div>
                            <div className="picture col-lg-6"><img id="promo" alt=""/></div>
                        </div>
                    </div>
                    <div className="actions">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-4">
                                    <div className="ms-card">
                                        <div className="ms-card-pic"><i className="fas fa-paragraph fa-3x"/></div>
                                        <div className="ms-card-title">
                                            <h2>Create Forms</h2>
                                            <span/>
                                        </div>
                                        <div className="ms-card-text">
                                            <p>Create an unlimited quantity of forms, and send them to your students for you to quiz them, and support them!</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="ms-card">
                                        <div className="ms-card-pic"><i className="fas fa-wallet fa-3x"/></div>
                                        <div className="ms-card-title">
                                            <h2>Free to Use</h2>
                                            <span/>
                                        </div>
                                        <div className="ms-card-text">
                                            <p>We believe learning should be accessible for anyone in the world, that's why EduQuiz is free, forever.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="ms-card">
                                        <div className="ms-card-pic"><i className="fas fa-birthday-cake fa-3x"/>
                                        </div>
                                        <div className="ms-card-title">
                                            <h2>Easy to Use</h2>
                                            <span/>
                                        </div>
                                        <div className="ms-card-text">
                                            <p>It's easy as cake to use EduQuiz. Just create an account, and you're set, up and ready to go!</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
        )
    }

}