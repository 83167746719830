import React, { Component } from 'react';
import Header from "../components/Header";
import {Link} from "react-router-dom";
import Loading from "../components/Loading";
import Footer from "../components/Footer";

const initialState = {
    name: "",
    email: "",
    password: "",
    type: "",
    otherError: "",
    nameError: "",
    emailError: "",
    passwordError: "",
    accountRegistered: false,
    data: {},
    token: localStorage.getItem("token"),
    auth_working: true,
    api_issue: false,
};

export default class Register extends Component {

    constructor(props) {
        super(props);

        this.state = initialState;
        
    }

    handle_change = event => {
        if (['username', 'password', 'email', 'type'].indexOf(event.target.name) === -1)
            return;
        
        const state = {};

        state[event.target.name] = event.target.value;
        this.setState(state);
    }

    validate = () => {
        let nameError = "", emailError = "", passwordError = "";

        if (!this.state.username.match(/^(?!.*\.\.)(?!.*\.$)[^\W][\w.]{0,29}$/))
            nameError = "Your name does not meet the desired requirements";

        if (!this.state.email.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/))
            emailError = "The email specified does not meet the desired requirements";

        if (!this.state.password.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/gm))
            passwordError = "The password specified must be at least 8 characters long, contain at least 1 uppercase letter, and 1 number";

        if (emailError || nameError || passwordError) {
            this.setState({ emailError, nameError, passwordError });
            return false;
        }

        return true;
    }

    validateToken = async (token) => {
        try {
            const request = await fetch(`https://api.educationapp.luke.mx/data?token=${token}`);
            const json = await request.json();

            if (json.success) {
                this.setState({
                    valid: true,
                    data: json.data
                });
            } else {
                this.setState({
                    valid: false
                });
            }

            this.setState({ auth_working: false });
            return json;
        } catch {
            this.setState({
                api_issue: true
            });
        }
    }

    handle_submit = async (event) => {

        console.log(event)
        event.preventDefault();

        if (this.state.working)
            return;

        const isValid = this.validate();
        this.setState({ accountRegistered: false });

        if (isValid) {
            this.setState(initialState);

            try {
                const request = await fetch(`https://api.educationapp.luke.mx/register`, {
                    method: 'POST',
                    body: JSON.stringify({
                        username: this.state.username,
                        password: this.state.password,
                        email: this.state.email,
                        type: "student"
                    }),
                    headers: {
                        'content-type': 'application/json'
                    }
                });


                const json = await request.json();
                console.log(json);

                this.setState({
                    working: false,
                    auth_working: false
                });

                if (!json.success) {
                    if (json.type === 'username')
                        this.setState({ nameError: json.message });
                    else if (json.type === "email")
                        this.setState({ emailError: json.message });
                    else if (json.type === "password")
                        this.setState({ passwordError: json.message });
                    else this.setState({ otherError: json.message });
                }
                else {
                    this.setState({ accountRegistered: true})
                }
            } catch {
                this.setState({ otherError: "Unable to connect to server backend API server" });
            }

            return false;
        }

    }

    async componentWillMount() {
        await this.validateToken(this.state.token);
    }

    render() {

        if (this.state.accountRegistered) {
            return (
                <div>
                    <Header active="register" />
                    <div className="navPusher">
                        <center><h1>Your account has been successfully registered!<br/><Link to="/login">Click here</Link> to login to your account!</h1></center>
                    </div>
                </div>
            )
        }

        if (this.state.api_issue === true) {
            return (<div>
                <Header active="register" />
                <div className="navPusher">
                    <center><h1>You were not able to access the API, it may be down. Try again later.</h1></center>
                </div>
            </div>)
        }

        if (this.state.auth_working) {
            return (<Loading />)
        }

        if (this.state.data.account?.type) {
            return (
                <div>
                    <Header active="register" logged="true" username={this.state.data.account.username}/>
                    <div className="navPusher">
                        <center>
                            <h1>{this.state.data.account.username}, you are already logged in, you must <Link to="/logout">logout</Link> to view this page.</h1>
                        </center>
                    </div>
                </div>
            )
        }

        return (
            <div>
                <Header active="register" logged="false"/>
                <div className="navPusher">
                    <div className="text container item-group">
                        <form onSubmit={this.handle_submit}>
                            <h1>Register</h1>
                            {this.state.otherError !== '' ? (
                                <div className="ms-alert ms-danger">
                                    {this.state.otherError}
                                </div>
                            ) : null}
                            <div>
                                {this.state.nameError !== '' ? (
                                    <div className="ms-alert ms-danger">
                                        {this.state.nameError}
                                    </div>
                                ) : null}
                                <strong>Username:</strong>
                                <input name="username" disabled={this.state.working} value={this.state.username} onChange={this.handle_change} type="text" placeholder="Enter username" required/>
                            </div><br/>
                            <div>
                                {this.state.emailError !== '' ? (
                                    <div className="ms-alert ms-danger">
                                        {this.state.emailError}
                                    </div>
                                ) : null}
                                <strong>Email:</strong>
                                <input name="email" type="email" disabled={this.state.working} placeholder="Enter email" value={this.state.email} onChange={this.handle_change} required/>
                            </div><br/>
                            <div>
                                {this.state.passwordError !== '' ? (
                                    <div className="ms-alert ms-danger">
                                        {this.state.passwordError}
                                    </div>
                                ) : null}
                                <strong>Password:</strong>
                                <input name="password" type="password" placeholder="Enter password" disabled={this.state.working} value={this.state.password} onChange={this.handle_change}/>
                            </div>
                            <button type="submit" disabled={this.state.working} value="Login">Register</button> <Link to='/login'><button>Login Instead</button></Link>
                        </form>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }

}