import React from "react";

export default class Dashboard extends React.Component {

    constructor(props) {
        super(props);

        let default_checked = {};
        this.props.data.answers.forEach((_, index) => default_checked[index] = false);

        this.state = {
            question: this.props.data,
            data: [],
            show_hints: false,
            checked: default_checked
        }
    }

    handle_check = (e, i) => {
        let state = this.state.checked;
        state[i] = e.target.checked;
        this.setState({ checked: state });
        this.props.onChange(state);
    }

    render () {

        return (
            <div>
                <br/>
                <strong>Name: </strong>
                <span>{this.props.data.title}</span>
                <br/>
                <strong>Description: </strong>
                <span>{this.props.data.description}</span>
                <br/>
                {this.state.show_hints ? (
                    <div>
                        <strong>Hints: </strong>
                        <ul>
                        {this.state.question.hints.map(hint => {
                            return (
                                <li>
                                    {hint}
                                </li>
                            )
                        })}
                        </ul>
                        <br/>
                        <button onClick={event => {
                            event.preventDefault();

                            this.setState({
                               show_hints: false
                            });
                        }}>Hide Hints</button>
                    </div>
                ) : (
                    <div>
                        <strong>Hints: </strong>
                        <span>Hidden</span><br/>
                        <button onClick={event => {
                            event.preventDefault();

                            this.setState({
                                show_hints: true
                            });
                        }}>Show Hints</button>
                    </div>
                )}
                <br/>
                {this.state.question.answers.map((answer, index) => {
                    return (
                        <div>
                            <input type="checkbox" checked={this.state.checked[index]} onChange={(e) => this.handle_check(e, index)} /> <label>{answer.name}</label>
                        </div>
                    )
                })}
            </div>
        )

    }


}