import React from "react";
import { Link } from 'react-router-dom';
import Header from "../components/Header";
import Loading from "../components/Loading";
import Footer from "../components/Footer";

export default class Dashboard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            token: localStorage.getItem("token"),
            valid: null,
            working: true,
            submit_working: false,
            failed: false,
            created: false,
            created_link: '',
            failed_reason: '',
            data: {},
            form: {
                name: '',
                description: '',
                questions: []
            },
            temp_question: {
                name: '',
                description: '',
                marks: 1,
                type: 'multiple choice',
                hints: [],
                answers: [],
                correct_answer: false,
            },
            temp_answer: {
                correct: false,
                name: ''
            },
            temp_hint: '',
        }
    }

    validateToken = async (token) => {
        const request = await fetch(`https://api.educationapp.luke.mx/data?token=${token}`);
        const json = await request.json();

        if (json.success) {
            this.setState({
                valid: true,
                data: json.data
            });
        } else {
            this.setState({
                valid: false
            });
        }

        this.setState({working: false});
        return json;

    }

    async componentWillMount() {
        await this.validateToken(this.state.token);
    }

    render() {

        if (this.state.working) return <Loading/>

        if (!this.state.token) {
            return (
                <div>
                    <Header active="create-form" />
                    <div className="navPusher">
                        <center><h1>You need to be logged in to access this page.<br/><Link to="/login">Click here to login to an account if not done already.</Link></h1></center>
                    </div>
                </div>)
        } else if (!this.state.data.account?.type)
            return (
                <div>
                    <Header active="create-form" />
                    <div className="navPusher">
                        <center><h1>You are not logged in, your session may have expired.<br/><Link to="/login">Click here to login to an account if not done already.</Link></h1></center>
                    </div>
                </div>
            )

        if (this.state.created)
            return (
                <div>
                    <Header active="create-form" logged="true" username={this.state.data.account.username}/>
                    <div className="navPusher">
                        <center><h1>Successfully created form, <Link to={`/form/${this.state.created_link}`}>view it here</Link>.</h1></center>
                    </div>
                </div>
            )

        return (
            <div>
                <Header active="create-form" logged="true" username={this.state.data.account.username}/>
                <div className="navPusher">
                    <div className="text container item-group">
                {this.state.failed ? (
                    <div className="ms-alert ms-danger">
                        Error: {this.state.failed_reason}
                    </div>
                ) : null}
                <h1>Create New Form</h1>
                {this.state.form.questions && this.state.form?.questions.length ? (
                    <div>
                        <h4>Current Questions</h4>
                        <ul>
                            {
                                this.state.form.questions.map((question, i) => {
                                    return (
                                        <li>
                                            <strong>Name:</strong> {question.name}<br/>
                                            <strong>Description:</strong> {question.description}<br/>
                                            <strong>Marks:</strong> {question.marks}<br/>
                                            <strong>Type:</strong> Multiple Choice<br/>
                                            <strong>Answers:</strong>
                                            <ul>{question.answers.map(answer => {
                                                return (
                                                    <li>{answer.name}: {answer.correct === true ? "Correct" : "Incorrect"}</li>
                                                )
                                            })}</ul>
                                            <strong>Hints:</strong> {question.hints.length ? (
                                            <ul>{question.hints.map(hint => {
                                                return (
                                                    <li>{hint}</li>
                                                )
                                            })}</ul>) : ("None")}
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                ) : null}
                <form onSubmit={this.handle_submit}>
                    <h3>Form Information:</h3>
                    <strong>Name: </strong>
                    <input
                        value={this.state.form.name}
                        type="text"
                        onChange={event => {
                            let s = this.state.form;
                            s.name = event.target.value;

                            this.setState({
                                form: s
                            });
                        }}
                    /><br/>
                    <strong>Description: </strong>
                    <textarea
                        value={this.state.form.description}
                        type="text"
                        onChange={event => {
                            let s = this.state.form;
                            s.description = event.target.value;

                            this.setState({
                                form: s
                            });
                        }}
                    /><br/>
                </form>
                <h3>Create Question</h3>
                <form>
                    <strong>Name: </strong>
                    <input
                        value={this.state.temp_question.name}
                        type="text"
                        onChange={event => {
                            let s = this.state.temp_question;
                            s.name = event.target.value;

                            this.setState({
                                temp_question: s
                            });
                        }}
                    /><br/>
                    <strong>Description: </strong>
                    <textarea
                        value={this.state.temp_question.description}
                        type="text"
                        onChange={event => {
                            let s = this.state.temp_question;
                            s.description = event.target.value;

                            this.setState({
                                temp_question: s
                            });
                        }}
                    /><br/>
                    <strong>Marks: </strong>
                    <input
                        value={this.state.temp_question.marks}
                        type="number"
                        onChange={event => {
                            let s = this.state.temp_question;
                            s.marks = event.target.value < 1 || !Number(event.target.value) ? this.state.temp_question.marks : Number(event.target.value);

                            this.setState({
                                temp_question: s
                            });
                        }}
                    /><br/>
                    <button onClick={event => {
                        event.preventDefault();

                        let q = this.state.temp_question;
                        q.marks++;

                        this.setState({
                            temp_question: q
                        });
                    }}>Add</button> <button onClick={event => {
                        event.preventDefault();

                        let q = this.state.temp_question;
                        q.marks--;

                        this.setState({
                            temp_question: q
                        });
                }} disabled={this.state.temp_question.marks <= 1}>Subtract </button>
                    <br/><br/>
                    <strong>Type: </strong><br/>
                    <input type="radio" checked readOnly/> <label>Multiple Choice</label><br/>
                    <input
                        type="radio"
                        disabled
                    /> <label>Short Answer</label><br/>
                    <input
                        type="radio"
                        disabled
                    /> <label>Long Answer</label><br/><br/>
                    <label><strong>Create Hint</strong></label><br/>
                    {this.state.temp_question.hints && this.state.temp_question.hints?.length ? (
                        <div>
                            <h4>Current Hints</h4>
                            <ul>
                                {
                                    this.state.temp_question.hints.map((hint, i) => {
                                        return (
                                            <li>
                                                {hint}
                                                <button onClick={event => {
                                                    event.preventDefault();

                                                    let hints = this.state.temp_question.hints;
                                                    hints.splice(i, 1);

                                                    let tq = this.state.temp_question;
                                                    tq.hints = hints === [null] ? [] : hints;

                                                    this.setState({
                                                        temp_question: tq
                                                    });

                                                }
                                                }>Delete
                                                </button>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    ) : null}
                    <input
                        type="text"
                        value={this.state.temp_hint}
                        onChange={event => {
                            this.setState({
                                temp_hint: event.target.value
                            });
                        }}
                    /><br/>
                    {!this.state.temp_question.hints.includes(this.state.temp_hint) && this.state.temp_hint !== "" ? (
                        <button onClick={event => {
                            event.preventDefault();

                            let s = this.state.temp_question.hints;
                            let sq = this.state.temp_question;

                            s.push(this.state.temp_hint);
                            sq.hints = s;

                            this.setState({
                                temp_question: sq,
                                temp_hint: ''
                            });
                        }}>Add Hint to Question
                        </button>
                    ) : (
                        <button disabled>Add Hint to Question</button>
                    )}


                    <h3>Create Answer</h3>
                    {this.state.temp_question.answers && this.state.temp_question.answers?.length ? (
                        <div>
                            <h4>Current Answers</h4>
                            <ul>
                                {
                                    this.state.temp_question.answers.map((answer, i) => {
                                        return (
                                            <li>
                                                {answer.name}: {answer.correct === true ? "Correct" : "Incorrect"}
                                                <button onClick={event => {
                                                    event.preventDefault();

                                                    let answers = this.state.temp_question.answers;
                                                    answers.splice(i, 1);

                                                    let tq = this.state.temp_question;
                                                    tq.answers = answers === [null] ? [] : answers;

                                                    this.setState({
                                                        temp_question: tq
                                                    });

                                                }
                                                }>Delete
                                                </button>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    ) : null}
                    <strong>Answer: </strong>
                    <input
                        type="text"
                        value={this.state.temp_answer.name}
                        onChange={event => {
                            let s = this.state.temp_answer;
                            s.name = event.target.value;

                            this.setState({
                                temp_answer: s
                            });
                        }}
                    /><br/>
                    <strong>Correct: </strong>
                    <input
                        type="checkbox"
                        checked={this.state.temp_answer.correct}
                        onChange={event => {
                            let s = this.state.temp_answer;
                            s.correct = event.target.checked;

                            this.setState({
                                temp_answer: s
                            });
                        }}
                    /><br/>
                    {!this.state.temp_question.answers.map(answer => answer.name).includes(this.state.temp_answer.name) && this.state.temp_answer.name !== "" ? (
                        <button onClick={event => {
                            event.preventDefault();

                            let se = this.state.temp_question.answers;
                            let sq = this.state.temp_question;

                            se.push(this.state.temp_answer);
                            sq.answers = se;

                            if (sq.correct_answer !== true && this.state.temp_answer.correct === true)
                                sq.correct_answer = true;

                            this.setState({
                                temp_question: sq,
                                temp_answer: {
                                    name: '',
                                    correct: false
                                }
                            });
                        }}>Add Answer to Question</button>
                    ) : (
                        <button disabled>Add Answer to Question</button>
                    )}

                    <br/><br/>
                    {this.state.temp_question.answers.length && this.state.temp_question.answers.length >= 2 && this.state.temp_question.name && this.state.temp_question.correct_answer === true && this.state.temp_question.description ? (
                        <button onClick={event => {

                            event.preventDefault();

                            let s = this.state.form.questions;
                            let sq = this.state.form;

                            s.push(this.state.temp_question);
                            sq.questions = s;

                            this.setState({
                                form: sq,
                                temp_question: {
                                    name: '',
                                    description: '',
                                    marks: 1,
                                    type: 'multiple choice',
                                    hints: [],
                                    answers: [],
                                    correct_answer: false
                                },
                                temp_hint: '',
                                temp_answer: {
                                    name: '',
                                    correct: false
                                }
                            });
                        }}>Create Question</button>
                    ) : (
                        <button disabled>Create Question</button>
                    )} {this.state.form.questions.length && this.state.form.name && this.state.form.description ? (
                        <button onClick={async (event) => {
                            event.preventDefault();

                            let questionData = [];

                            this.state.form.questions.forEach(question => {
                                questionData.push({
                                    title: question.name,
                                    description: question.description,
                                    type: "multiple choice",
                                    marks: question.marks,
                                    answers: question.answers,
                                    hints: question.hints
                                });
                            });

                            try {
                                const request = await fetch('https://api.educationapp.luke.mx/createForm', {
                                    method: 'POST',
                                    headers: {
                                        'content-type': 'application/json'
                                    },
                                    body: JSON.stringify({
                                        token: this.state.token,
                                        type: "form",
                                        title: this.state.form.name,
                                        description: this.state.form.description,
                                        questions: questionData,
                                    })
                                });

                                const json = await request.json();

                                if (!json.success) {
                                    this.setState({
                                        failed: true,
                                        failed_reason: json.message
                                    });
                                } else {
                                    this.setState({
                                        created: true,
                                        created_link: json.data.form
                                    });
                                }
                            } catch {
                                this.setState({
                                    failed: true,
                                    failed_reason: 'Could not connect with backend API to complete request'
                                });
                            }


                        }}>Submit & Create Form</button>
                    ) : (
                        <button disabled>Submit & Create Form</button>
                    )}
                </form></div><Footer /></div>
            </div>)
    }

};