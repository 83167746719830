import React from 'react';
import { Link } from "react-router-dom";
import Header from "../components/Header";
import Loading from "../components/Loading";
import Footer from "../components/Footer";

export default class Login extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            name: "",
            password: "",
            failed: "",
            valid: null,
            working: false,
            auth_working: true,
            api_issue: false,
            token: localStorage.getItem("token"),
            data: {}
        };
    }

    handle_change = event => {
        if (['username', 'password'].indexOf(event.target.name) === -1)
            return;

        const state = {};

        state[event.target.name] = event.target.value;
        this.setState(state);
    }

    validateToken = async (token) => {
        try {
            const request = await fetch(`https://api.educationapp.luke.mx/data?token=${token}`);
            const json = await request.json();

            if (json.success) {
                this.setState({
                    valid: true,
                    data: json.data
                });
            } else {
                this.setState({
                    valid: false
                });
            }

            return json;
        } catch {
            this.setState({
                api_issue: true
            });
        }
    }

    handle_submit = async (event) => {

        event.preventDefault();

        try {
            const request = await fetch(`https://api.educationapp.luke.mx/login`, {
                method: 'POST',
                body: JSON.stringify({
                    username: this.state.username,
                    password: this.state.password
                }),
                headers: {
                    'content-type': 'application/json'
                }
            });

            const json = await request.json();

            if (!json.success) {
                this.setState({ failed: json.message });
            }
            else {
                console.log('successful');
                localStorage.setItem("token", json.data.token);
                window.location.pathname = '/dashboard';
            }
        } catch {
            this.setState({
                failed: "Unable to connect to server backend API server"
            });
        }
    }

    async componentWillMount() {
        await this.validateToken(this.state.token);

        this.setState({ auth_working: false });
    }

    render() {

        if (this.state.api_issue === true) {
            return (
                <div>
                    <Header active="login" />
                    <div className="navPusher">
                        <center><h1>You were not able to access the API, it may be down. Try again later.</h1></center>
                    </div>
                </div>
            )
        }

        if (this.state.auth_working) {
            return (<Loading />)
        }

        if (this.state.data.account?.type) {
            return (
                <div>
                    <Header active="login" logged="true" username={this.state.data.account.username}/>
                    <div className="navPusher">
                        <center>
                            <h1>{this.state.data.account.username}, you are already logged in, you must <Link to="/logout">logout</Link> to view this page.</h1>
                        </center>
                    </div>
                </div>
            )
        }

        return (
            <div>
                <Header active="login" logged="false"/>
                <div className="navPusher">
                    <div className="text container item-group">
                <form onSubmit={this.handle_submit}>
                    <h1>Login</h1>
                    {this.state.failed !== '' ? (
                        <div className="ms-alert ms-danger">
                            {this.state.failed}
                        </div>
                    ) : null}
                    <div>
                        <strong>Username:</strong>
                        <input type="text" disabled={this.state.working} name="username" placeholder="Enter username" value={this.state.username} onChange={this.handle_change} required/>
                    </div><br/>
                    <div>
                        <strong>Password:</strong>
                        <input type="password" disabled={this.state.working} name="password" placeholder="Enter password" value={this.state.password} onChange={this.handle_change} required/>
                    </div>
                    <button type="submit" disabled={this.state.working} value="Login">Login</button> <Link to='/register'><button>Register Instead</button></Link> <button disabled>Forgot My Password</button>
                </form>
                    </div>
                </div>
                <Footer />
            </div>

        )
    }

}