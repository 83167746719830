import React from "react";
import Moment from 'react-moment';

export default class CurrentSubmission extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show_hints: false
        }
    }

    render () {

        return (
            <div>
                <br/>
                <strong>Submission Username: </strong> {this.props.questions.username ?? 'N/A'}<br/>
                <strong>Submission Time: </strong> {this.props.questions.time ? <Moment format="DD/MM/YYYY HH:mm">{this.props.questions.time}</Moment> : 'N/A'}

                <h1>Questions: </h1>
                {this.props.form.questions.map((question, qpos) => {
                    let correct = false;

                    question.answers.map((ans, anspos) => {
                        if (ans.correct === true && this.props.questions.data[qpos].answers[anspos] === true)
                            correct = true;
                    });

                    return (
                        <div>
                            <strong>Type: </strong> Multiple Choice<br/>
                            <strong>Title: </strong> {question.title}<br/>
                            <strong>Description: </strong> {question.description}<br/>
                            <strong>Marks: </strong> {JSON.stringify(question.marks)}<br/>
                            <strong>Status: </strong> {correct === true ? (
                                <span style={{color: "green"}}>Correct</span>
                        ) : (
                                <span style={{color: "red"}}>Incorrect</span>
                        )}<br/><br/>
                            <strong>Answers:</strong><br/>
                            {question.answers.map((answer, apos) => {
                                return (
                                    <div>
                                        {answer.correct === true ? ("Correct") : "Incorrect"} <input type="checkbox" checked={this.props.questions.data[qpos].answers[apos]} disabled/> <label>{answer.name}</label>
                                    </div>
                                )
                            })}<br/>
                        </div>
                    )
                })}
            </div>
        )

    }


}