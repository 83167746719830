import React from 'react';
import { Link } from "react-router-dom";
import Header from "../components/Header";

export default class Logout extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            token: localStorage.getItem("token"),
        };

    }

    async componentDidMount() {
        await localStorage.setItem("token", null);
    }

    render() {
        return (
            <div>
                <Header active="logout" logged="false"/>
                <div className="navPusher">
                    <center><h1>You have been logged out of your account.<br/>
                        You can <a href="/login">login</a> with another account, or <a href="/register">create a new one</a>.
                    </h1></center>
                </div>
            </div>
        )
    }

}