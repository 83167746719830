import React from "react";
import {Link} from "react-router-dom";

const Footer = () => {
    return (
        <footer className="ms-footer">
            <div className="container">
                <div className="ms_col ms_col--11-of-12 ms_col--centered">
                    <section className="copyright">Copyright © 2021 — Luke Matison & Nathan Wijaya<br/><Link to="/terms">Terms and Conditions</Link> • <Link to="/privacy">Privacy Policy</Link></section>
                </div>
            </div>
        </footer>
    )
}

export default Footer;