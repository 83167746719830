import React from "react";
import { Link } from 'react-router-dom';
import Header from "../components/Header";
import Loading from "../components/Loading";
import Footer from "../components/Footer";

export default class Dashboard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            token: localStorage.getItem("token"),
            valid: null,
            valid_form: null,
            failed_reason: '',
            working: true,
            data: {},
            form: {},
            show_answers: false,
            form_id: window.location.pathname.replace(/(\/form\/)|(\/form)/g, '')
        }
    }

    validateToken = async (token) => {
        const request = await fetch(`https://api.educationapp.luke.mx/data?token=${token}`);
        const json = await request.json();

        if (json.success) {
            this.setState({
                valid: true,
                data: json.data
            });
        } else {
            this.setState({
                valid: false
            });
        }

        return json;

    }

    getFormData = async (token, form) => {
        const request = await fetch(`https://api.educationapp.luke.mx/getForm?token=${token}&id=${form}`);
        const json = await request.json();

        if (!json.success) {
            this.setState({
                valid_form: false,
                failed_reason: json.message
            });
        } else {
            this.setState({
                valid_form: true,
                form: json.data
            });
        }

        return json;
    }

    async componentWillMount() {

        await this.validateToken(this.state.token);
        await this.getFormData(this.state.token, this.state.form_id);
        this.setState({ working: false });

    }

    render() {

        if (this.state.working) {
            return (<Loading />)
        }

        if (!this.state.token) {
            return (
                <div>
                    <Header />
                    <div className="navPusher">
                        <center><h1>You need to be logged in to access this page.<br/><Link to="/login">Click here to login to an account if not done already.</Link></h1></center>
                    </div>
                </div>)
        }

        else if (!this.state.data.account?.type)
            return (
                <div>
                    <Header />
                    <div className="navPusher">
                        <center><h1>You are not logged in, your session may have expired.<br/><Link to="/login">Click here to login to an account if not done already.</Link></h1></center>
                    </div>
                </div>
            )

        else if (!this.state.valid_form) {
            if (this.state.failed_reason === "You must provide a valid form ID")
                return (
                    <div>
                        <Header logged="true" username={this.state.data.account.username}/>
                        <div className="navPusher">
                            <center><h1>You must provide a form ID that is valid</h1></center>
                        </div>
                    </div>
                )
            else return (
                <div>
                    <Header logged="true" username={this.state.data.account.username}/>
                    <div className="navPusher">
                        <center><h1>{this.state.failed_reason}</h1></center>
                    </div>
                </div>
            )
        }

        const formData = this.state.form;

        function copy () {
            const input = document.createElement('input');
            input.value = window.location.href;

            document.body.appendChild(input);
            input.select();

            document.execCommand('copy');
            document.body.removeChild(input);
        }

        return (
            <div>
                <Header logged="true" username={this.state.data.account.username}/>
                <div className="navPusher">
                    <div className="text container item-group">
                <h1>{formData?.title ?? 'N/A'} — Form</h1>
                <h2>Basic Information</h2>
                <strong>Title: </strong>
                <span>{formData?.title ?? 'N/A'}</span>
                <br/>
                <strong>Description: </strong>
                <span>{formData?.description ?? 'N/A'}</span>
                <br/>
                <strong>Submissions Amount: </strong>
                <span>{formData?.totalSubmissions ?? '0'}</span>
                <br/>
                <strong>Owner: </strong>
                <span>{formData?.username}</span>
                <p>
                    <Link to="/dashboard"><button>Return to Dashboard</button></Link> <button onClick={copy}>Copy Link</button>
                    <br/>
                    {this.state.data?.account?.username === this.state.form.username ? (
                        <Link to={`/submissions/${this.state.form_id}`}>
                            <button>View Submissions</button>
                        </Link>
                    ) : (
                        <button disabled>View Submissions</button>
                    )} <Link to={`/submit-form/${this.state.form_id}`}>
                        <button>Submit Response</button>
                    </Link>
                </p>
                <h2>Questions: </h2>
                {formData?.questions && formData.questions.length >= 1 ? (
                    <div>
                        {formData.questions.map(question => {
                            return (
                                <div>
                                    <strong>Question Name: </strong>
                                    <span>{question?.title ?? 'N/A'}</span>
                                    <br/>
                                    <strong>Question Description: </strong>
                                    <span>{question?.description ?? 'N/A'}</span>
                                    <br/>
                                    <strong>Question Type: </strong>
                                    <span>{question?.type ?? 'N/A'}</span>
                                    <br/>
                                    <strong>Question Marks: </strong>
                                    <span>{question.marks ?? '0'}</span>
                                    <br/>
                                    <strong>Answers: </strong>
                                    {this.state.show_answers === true ? (
                                        <ul>
                                            {question.answers.map(answer => {
                                                return (
                                                    <li>
                                                        <strong>{answer.name}: </strong> {answer.correct === true ? "Correct" : "Incorrect"}
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    ) : (
                                        <span>Hidden</span>
                                    )}<br/><br/>
                                </div>

                            )
                        })}
                        <button onClick={event => {

                            event.preventDefault();

                            this.setState({
                                show_answers: !this.state.show_answers
                            });

                        }}>{this.state.show_answers === true ? "Hide Answers" : "Show Answers"}</button>
                    </div>
                ) : (
                    <span>There are no questions in this form :(</span>
                )}
                    </div>
                </div>
                <Footer />
            </div>
        )
    }

};