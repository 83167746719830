import React, { Component } from "react";
import "../resources/style/loading.css";

export default class Loading extends Component {
    render() {
        return (
            <div className="loader-wrapper">
                <span className="loader">
                    <span className="loader-inner"/>
                </span>
            </div>
        )
    }

}