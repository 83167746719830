import React from 'react';
import Header from "../components/Header";
import {Link} from "react-router-dom";

export default class Home extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            valid: null,
            working: false,
            auth_working: true,
            api_issue: false,
            token: localStorage.getItem("token"),
            data: {}
        };
    }

    validateToken = async (token) => {
        try {
            const request = await fetch(`https://api.educationapp.luke.mx/data?token=${token}`);
            const json = await request.json();

            if (json.success) {
                this.setState({
                    valid: true,
                    data: json.data
                });
            } else {
                this.setState({
                    valid: false
                });
            }

            return json;
        } catch {
            this.setState({
                api_issue: true
            });
        }
    }

    async componentWillMount() {
        await this.validateToken(this.state.token);

        this.setState({ auth_working: false });
    }

    render() {
        return (
            <div>
                {this.state?.data?.account?.username ? (
                    <Header logged="true" username={this.state.data.account.username}/>
                ) : (
                    <Header logged="false"/>
                )}
                <div className="navPusher">
                    <center><h1>Error — 404 Page Not Found<br/><Link to="/">Click here to return to the homepage of EduQuiz.</Link></h1></center>
                </div>
            </div>
        )
    }

}