import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
// import './style/main.css';

import Home from './views/Home';
import Login from './views/Login';
import Register from "./views/Register";
import PageNotFound from "./views/404";
import Dashboard from "./views/Dashboard";
import ViewForm from "./views/ViewForm";
import Logout from "./views/Logout";
import SubmitForm from "./views/SubmitForm";
import ViewFormSubmissions from "./views/ViewFormSubmissions";
import CreateForm from "./views/CreateForm";
import TOS from "./views/TermsAndConditions";
import Privacy from "./views/PrivacyPolicy";

ReactDOM.render(
    <React.StrictMode>

        <BrowserRouter>
            <Switch>

                <Route exact path="/">
                    <Home />
                </Route>

                <Route path="/login">
                     <Login />
                </Route>

                <Route path="/register">
                    <Register />
                </Route>

                <Route path="/dashboard">
                     <Dashboard />
                </Route>

                <Route path="/form">
                    <ViewForm />
                </Route>

                <Route path="/submit-form">
                    <SubmitForm />
                </Route>

                <Route path="/submissions">
                    <ViewFormSubmissions />
                </Route>

                <Route path="/create-form">
                    <CreateForm />
                </Route>

                <Route path="/logout">
                    <Logout />
                </Route>

                <Route path="/terms">
                    <TOS />
                </Route>

                <Route path="/privacy">
                    <Privacy />
                </Route>

                <Route path="/">
                    <PageNotFound />
                </Route>


            </Switch>
        </BrowserRouter>

    </React.StrictMode>,
    document.getElementById('root')
);