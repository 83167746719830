import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class Header extends Component {
    getInitials = (string) => {
        let names = string.split(' '),
            initials = names[0].substring(0, 1).toUpperCase();

        if (names.length > 1) {
            initials += names[names.length - 1].substring(0, 1).toUpperCase();
        }
        return initials;
    };

    render() {
        return (
            <div>
                {this.props?.logged === "true" ? (
                        <div className="fixedHeaderContainer">
                            <div className="headerWrapper wrapper">
                                <header>
                                    <a>
                                        <Link to="/"><h3>EduQuiz</h3></Link>
                                    </a>
                                    <div className="navigationWrapper navigationSlider">
                                        <nav className="slidingNav">
                                            <ul className="nav-site nav-site-internal">
                                                <li className={this.props?.active === "home" ? "siteNavItemActive" : ""}><Link to="/" target="_self">Home</Link></li>
                                                <li className={this.props?.active === "dashboard" ? "siteNavItemActive" : ""}><Link to="/dashboard" target="_self">Dashboard</Link></li>
                                                <li className={this.props?.active === "create-form" ? "siteNavItemActive" : ""}><Link to="/create-form" target="_self">Create Form</Link></li>
                                                <li className={this.props?.active === "logout" ? "siteNavItemActive" : ""}><Link to="/logout" target="_self">Logout</Link></li>
                                                <li>
                                                    <div style={{"margin-left": "15px"}} className="ms-avatar ms-small">
                                                        <span className="ms-text">{this.getInitials(this.props.username)}</span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </header>
                            </div>
                        </div>
                ) : (
                        <div className="fixedHeaderContainer">
                            <div className="headerWrapper wrapper">
                                <header>
                                    <a>
                                        <Link to="/"><h3>EduQuiz</h3></Link>
                                    </a>
                                    <div className="navigationWrapper navigationSlider">
                                        <nav className="slidingNav">
                                            <ul className="nav-site nav-site-internal">
                                                <li className={this.props?.active === "home" ? "siteNavItemActive" : ""}><Link to="/" target="_self">Home</Link></li>
                                                <li className={this.props?.active === "login" ? "siteNavItemActive" : ""}><Link to="/login" target="_self">Login</Link></li>
                                                <li className={this.props?.active === "register" ? "siteNavItemActive" : ""}><Link to="/register" target="_self">Register</Link></li>
                                            </ul>
                                        </nav>
                                    </div>
                                </header>
                            </div>
                        </div>
                )}
            </div>
        )
    }

}