import React from "react";
import { Link } from 'react-router-dom';
import Header from "../components/Header";
import Loading from "../components/Loading";
import Question from "./Question";
import Footer from "../components/Footer";

export default class Dashboard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            token: localStorage.getItem("token"),
            valid: null,
            valid_form: null,
            failed_reason: '',
            working: true,
            data: {},
            form: {},
            form_filled: [],
            show_hints: false,
            current_question: [],
            current_answers: [],
            was_submitted: false,
            submit_error: false,
            submit_error_reason: '',
            question: 1,
            question_total: 0,
            api_issue: false,
            form_id: window.location.pathname.replace(/(\/submit-form\/)|(\/submit-form)/g, '')
        }
    }

    validateToken = async (token) => {
        try {
            const request = await fetch(`https://api.educationapp.luke.mx/data?token=${token}`);
            const json = await request.json();

            if (json.success) {
                this.setState({
                    valid: true,
                    data: json.data
                });
            } else {
                this.setState({
                    valid: false
                });
            }

            return json;
        } catch {
            this.setState({
                api_issue: true
            });
        }
    }

    getFormData = async (token, form) => {
        const request = await fetch(`https://api.educationapp.luke.mx/getForm?token=${token}&id=${form}`);
        const json = await request.json();

        if (!json.success) {
            this.setState({
                valid_form: false,
                failed_reason: json.message
            });
        } else {
            this.setState({
                valid_form: true,
                form: json.data,
                question_total: json.data.questions.length
            });
        }

        return json;
    }

    async componentWillMount() {

        await this.validateToken(this.state.token);
        await this.getFormData(this.state.token, this.state.form_id);

        this.setState({
            working: false
        });

        console.log(this.state.form_filled);

    }

    render() {

        if (this.state.api_issue === true) {
            return (<div>
                <Header />
                <div className="navPusher">
                    <center><h1>You were not able to access the API, it may be down. Try again later.</h1></center>
                </div>
            </div>)
        }

        if (this.state.working) return <Loading/>;

        if (!this.state.token) {
            return (
                <div>
                    <Header />
                    <div className="navPusher">
                        <center><h1>You need to be logged in to access this page.<br/><Link to="/login">Click here to login to an account if not done already.</Link></h1></center>
                    </div>
                </div>)
        } else if (!this.state.data.account?.type)
            return (
                <div>
                    <Header />
                    <div className="navPusher">
                        <center><h1>You are not logged in, your session may have expired.<br/><Link to="/login">Click here to login to an account if not done already.</Link></h1></center>
                    </div>
                </div>
            )
        if (this.state.was_submitted)
            return (
                <div>
                    <Header logged="true" username={this.state.data.account.username}/>
                    <div className="navPusher">
                        <center><h1>Successfully responded to the form, <i>{this.state.form.title}</i> <Link to={`/form/${this.state.form_id}`}>view the form here</Link>!</h1></center>
                    </div>
                </div>
            )

        else if (!this.state.valid_form) {
            if (this.state.failed_reason === "You must provide a valid form ID")
                return (
                    <div>
                        <Header logged="true" username={this.state.data.account.username}/>
                        <div className="navPusher">
                            <center><h1>You must provide a form ID that is valid</h1></center>
                        </div>
                    </div>
                )
            else return (
                    <div>
                        <Header logged="true" username={this.state.data.account.username}/>
                        <div className="navPusher">
                            <center><h1>{this.state.failed_reason}</h1></center>
                        </div>
                    </div>
            )
        }

        const formData = this.state.form;

        function copy() {
            const input = document.createElement('input');
            input.value = window.location.href;

            document.body.appendChild(input);
            input.select();

            document.execCommand('copy');
            document.body.removeChild(input);
        }

        let includesTrue = 0;

        if (this.state.current_answers.length) {
            this.state.current_answers.forEach(answer => {
                if (Object.values(answer).includes(true) === true)
                    includesTrue++;
            });
        }

        const percent = `${(includesTrue / formData?.questions?.length) * 100 ?? 0}%`;

        return (

            <div>
                <Header logged="true" username={this.state.data.account.username}/>
                <div className="navPusher">
                    <div className="text container item-group">
                        <div className="ms-progress ms-large">
                            <div className="ms-progress-fill ms-info" style={{width: percent}}>{percent !== "0%" ? percent : null}</div>
                        </div>
                <h1>{formData?.title ?? 'N/A'} — Submit Form</h1>
                <h2>Information</h2>
                <strong>Title: </strong>
                <span>{formData?.title ?? 'N/A'}</span>
                <br/>
                <strong>Description: </strong>
                <span>{formData?.description ?? 'N/A'}</span>
                <br/>
                <strong>Question Amount: </strong>
                <span>{formData?.questions.length ?? "0"}</span>
                <br/>
                <strong>Submissions Amount: </strong>
                <span>{formData?.totalSubmissions ?? '0'}</span>
                <br/><br/>
                <p>
                    <Link to="/dashboard">
                        <button>Return to Dashboard</button>
                    </Link> <button onClick={copy}>Copy Link</button> {this.state.data?.account?.username === this.state.form.username ? (
                    <Link to={`/submissions/${this.state.form_id}`}>
                        <button>View Submissions</button>
                    </Link>
                ) : (
                    <button disabled>View Submissions</button>
                )}
                </p>
                <h2>Questions: </h2>
                {formData?.questions && formData.questions.length >= 1 ? formData.questions.map((question, index) => {
                    return <Question data={question} onChange={(v) => {
                        let state = this.state;
                        state.current_answers[index] = v;
                        this.setState(state);
                    }}/>
                }) : (
                    <div>
                        There are no questions in this form.
                    </div>
                )}<br/>
                {formData?.questions ? (
                    <button
                        onClick={async (event) => {
                            event.preventDefault();

                            console.log(this.state.form_filled);

                            console.log(JSON.stringify(this.state.current_answers))

                            try {
                                const request = await fetch(`https://api.educationapp.luke.mx/submitResponse`, {
                                    method: 'POST',
                                    headers: {
                                        'content-type': 'application/json'
                                    },
                                    body: JSON.stringify({
                                        token: this.state.token,
                                        type: "form",
                                        formID: this.state.form_id,
                                        questions: this.state.current_answers.map(question => Object.values(question))
                                    })
                                });

                                const json = await request.json();

                                if (json.success) {
                                    this.setState({
                                        was_submitted: true
                                    });
                                } else {
                                    this.setState({
                                        submit_error: true,
                                        submit_error_reason: json.message
                                    });
                                }
                            } catch (error) {
                                this.setState({
                                    submit_error: true,
                                    submit_error_reason: error
                                });
                                console.error(error);
                            }
                        }}
                        disabled={
                            this.state.current_answers
                                .filter((v) => !!Object.values(v).find(v => v === true)).length !== formData.questions.length
                        }
                    >Submit Response</button>
                ) : null}
                    </div></div> <Footer />
            </div>)
    }

};